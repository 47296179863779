import React from "react";
import CookieBanner from "./cookie";
import Footer from "./footer";
import Navigation from "./navigation";
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
	return (
		<>
			{/* <Helmet>
        {typeof Window !== "undefined" && (
          <script
            src="https://formspree.io/js/formbutton-v1.min.js"
            defer
          ></script>
        )}
        {typeof Window !== "undefined" && (
          <script
            type="text/javascript"
            innerHTML={`                   
               window.formbutton=window.formbutton||function(){(formbutton.q=formbutton.q||[]).push(arguments)};
    formbutton("create", {action: "https://formspree.io/f/xgerzagz"})`}
          />
        )}
      </Helmet> */}
			{/* <Navigation />
      <CookieBanner /> */}
			{children}
			{/* <Footer /> */}
		</>
	);
};

export default Layout;
